<template>
	<td>
		<span v-if="column.display == 'default'">{{ item[column.value] }} </span>
		<v-component
			v-else
			:is="column.display"
			:column="column"
			:item="item"
		></v-component>
	</td>
</template>

<style lang="less">
td.focused {
	background: #eeeeee !important;
}
</style>
<script>
export default {
	name: "AppointmentCell",
	props: {
		column: { type: Object },
		item: { type: Object },
	},
	components: {
		checkbox: () => import("./CheckboxCell.vue"),
		consent: () => import("./ConsentCell.vue"),
		prettyPrint: () => import("./StringCell.vue"),
		date: () => import("./DateCell.vue"),
		user: () => import("./UserCell.vue"),
		click_link: () => import("./LinkCell.vue"),
		tech_test: () => import("./TechTestCell.vue"),
		chip: () => import("./ChipCell.vue"),
		attendance: () => import("./AttendanceCell.vue"),
		question: () => import("./QuestionCell.vue"),
	},
};
</script>
