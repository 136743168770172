<template>
	<v-sheet color="transparent">
		<v-text-field v-model="search" label="Search"></v-text-field>
        <p class="text-right">
            <v-btn text @click="clearFilters" small color="primary">Clear Filters</v-btn>
        </p>
		<v-simple-table>
			<thead>
				<tr>
					<th v-if="haveSchedule">Date</th>
					<th>Participant</th>
					<th>Status</th>
					<th>Link</th>
					<th>Consented</th>
					<th>Attendance</th>
				</tr>
			</thead>
			<tbody>
				<session-row
					v-for="row in filteredRows"
					:key="row.id"
					:is-scheduled="haveSchedule"
					:appointmentStart="row.appointment_start"
                    :id="row.id"
                    :appointmentId="row.appointment"
					:user="row.user"
					:status="row.appointment_status"
                    :link="row.appointment_participation_link"
                    :medium="row.appointment_video_medium"
                    :consented="row.consented"
                    :comments="row.client_comments"
                    :project="row.appointment_project"
                    :trigger="trigger == row.appointment"
				>
				</session-row>
			</tbody>
		</v-simple-table>
	</v-sheet>
</template>
<style></style>

<script>
import SessionRow from "@c/project/SessionRow";
export default {
	name: "Session",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			search: null,
            includeAppointments: [],
            trigger: null
		};
	},
	components: {
		SessionRow,
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		rows() {
			let rows = this.$store.getters["singleAppointments/getByFilters"]({
				projects: [this.id],
			});
			return this.mwutils.sortByDate(rows, "appointment_start");
		},
		filteredRows() {
            const self = this;
            let rows = self.rows;
            if( self.includeAppointments.length ){
                rows = rows.filter( r => self.includeAppointments.includes(r.appointment) );
            }

            if( self.search ){
                rows = rows.filter( row => {
                    let string = Object.values(row).join("");
                    return string.includes(self.search);
                })
            }

            return rows;
		},
		haveSchedule() {
			if (this.project.incentive_only) {
				return false;
			}
			return this.project.include_scheduling;
		},
	},
    methods: {
        clearFilters(){
            this.search = null;
            this.includeAppointments = [];
        }
    },
    created(){
        if( this.$route.query.a ){
            this.includeAppointments = [this.$route.query.a]
        }
        if( this.$route.query.t ){
            this.trigger = this.$route.query.t;
        }
    }
};
</script>
