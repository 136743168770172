<template>
	<v-sheet color="transparent" v-if="project">
		<project-intro :id="project.id" intro="This page shows information about participants and appointments"></project-intro>
		<h3 class="font-weight-light">
			{{ mwutils.prettyPrint(viewType, "TITLE") }}
		</h3>
		<v-component :is="component" v-if="project" :id="id"></v-component>
		<project-navigation :id="id"></project-navigation>
	</v-sheet>
</template>
<style>
.v-expansion-panel-content__wrap {
	margin: 0 !important;
	padding: 0 !important;
}

.v-data-table__mobile-row {
	justify-content: left !important;
}
</style>

<script>
import AppointmentTable from "@c/project/AppointmentTable";
import ManageColumns from "@c/project/ManageColumns";
import ProjectIntro from "@c/project/ProjectIntro";
import ProjectNavigation from "@c/project/ProjectNavigation.vue";
import Sessions from "@c/project/Sessions.vue";
import Participants from "@c/project/Participants.vue";
export default {
	name: "Project",
	props: {
		id: { type: String },
		viewType: { type: String, default: "appointments" },
	},
	data: () => {
		return {
		};
	},
	provide() {
		return {
			projectId: this.id,
		};
	},
	components: {
		AppointmentTable,
		ManageColumns,
		ProjectNavigation,
		ProjectIntro,
		Sessions,
		Participants
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		singleAppointments() {
			return this.$store.getters["singleAppointments/getByFilters"]({projects: [this.id]})
		},
		component() {
			if( this.viewType == "sessions" ){
				return Sessions
			}
			if( this.viewType == "participants" ){
				return Participants
			}
			return null
		}
		
	},
	methods: {
	},
	watch: {
		project(v) {
			if (v && v.screeners) {
				this.$store.dispatch("surveys/fetchByIds", v.screeners);
			}
		},
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
		this.$store.dispatch("singleAppointments/fetchByFilters", {
			projects: [this.id],
		});
	},
};
</script>
