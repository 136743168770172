<template>
	<v-sheet color="transparent" v-if="project">
		<appointment-table v-if="project" :rows="rows" :columns="displayColumns">
			<template v-slot:append-buttons>
				<manage-columns
					colType="participants"
					:headers="allColumns"
				></manage-columns>
			</template>
		</appointment-table>
	</v-sheet>
</template>
<style>
.v-expansion-panel-content__wrap {
	margin: 0 !important;
	padding: 0 !important;
}

.v-data-table__mobile-row {
	justify-content: left !important;
}
</style>

<script>
import AppointmentTable from "@c/project/AppointmentTable";
import ManageColumns from "@c/project/ManageColumns";
export default {
	name: "Project",
	props: {
		id: { type: String },
	},
	data: () => {
		return {};
	},
	provide() {
		return {
			projectId: this.id,
		};
	},
	components: {
		AppointmentTable,
		ManageColumns,
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		screeners() {
			const self = this;
			return this.project.screeners.map((s) => {
				return self.$store.state.surveys.data[s];
			});
		},
		questions() {
			const self = this;
			let questions = self.screeners
				.filter((a) => a)
				.flatMap((s) => s.questions);
			return this.mwutils.uniqueArray(questions);
		},
		appointments() {
			return this.$store.getters.getByKey(
				"appointments",
				"project",
				this.id,
				true
			);
		},
		appointmentParticipants() {
			let ids = Object.keys(this.appointments);
			return this.$store.getters.getByKeyIn(
				"appointmentParticipants",
				"appointment",
				ids,
				false
			);
		},
		auth() {
			return this.$store.state.auth.data;
		},
		rows() {
			const self = this;
			return self.appointmentParticipants.map((appointmentParticipant) => {
				let appointment =
					self.appointments[appointmentParticipant.appointment];
				let participantColumns = self.makeParticipantRows(
					appointmentParticipant
				);
				let appointmentColumns = self.makeAppointmentRows(appointment);
				return {
					...appointmentColumns,
					...participantColumns,
					id: `${appointment.id}-${appointmentParticipant.id}`,
				};
			});
		},
		savedColumns() {
			let settings = this.$store.getters["auth/tableHeaders"](
				this.id,
				"participants"
			);
			return settings.length ? settings : this.defaultColumnsByType;
		},
		defaultColumnsByType() {
			let cols = this.$store.getters["tableHeaders/projectColumnsByType"](
				this.id,
				"participants"
			);
			cols = cols.concat(this.questionColumns.map((a) => a.value));

			return cols;
		},
		allColumns() {
			let columns = this.$store.getters["tableHeaders/projectColumns"](
				this.id
			);
			return columns.concat(this.questionColumns);
		},
		displayColumns() {
			let columns = this.allColumns;
			let savedColumns = this.savedColumns;
			if (savedColumns.length) {
				columns = columns.filter((a) => savedColumns.includes(a.value));
			}
			return columns;
		},
		questionColumns() {
			const self = this;
			let questions = self.questions.map(
				(value) => self.$store.state.questions.data[value]
			);
			questions = questions.filter((a) => a);
			questions = questions.filter((a) => !a.hide_from_results);
			return questions.flatMap((question) => {
				let text = self.$store.getters["questions/title"](question.id);
				let sub = false;
				if (question.type == "matrix") {
					sub = question.subquestions;
				}
				if (sub) {
					return Object.keys(sub).map((q) => {
						let row = sub[q];
						return {
							value: `${question.id}-${row.value}`,
							text: `${text} - ${row.text}`,
							display: "question",
							col_type: "participants",
							question: question.id,
							subquestion: row.value,
							filterable: true,
						};
					});
				} else {
					return {
						value: question.id,
						text,

						col_type: "participants",
						display: "question",
						question: question.id,
						width: "175px",
						filterable: true,
					};
				}
			});
		},
	},
	methods: {
		makeAppointmentRows(appointment) {
			return {
				...appointment,
				appointment_id: appointment.id,
			};
		},
		makeParticipantRows(participant) {
			return {
				...participant,
				participant_id: participant.id,
			};
		},
	},
};
</script>
