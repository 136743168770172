<template>
    <span>
        <p v-for="(response, i) in responses" :key="i" v-html="response">
            
        </p>
    </span>
</template>

<script>
export default {
    name: "QuestionResponse",
    props: {
        id: {},
        value: {}
    }, 
    computed: {
        question(){
            return this.$store.state.questions.data[this.id]
        }, 
        isString(){
            return typeof this.value == "string";
        }, 
        isLabelled(){
            return this.$store.getters["questions/isLabelled"](this.id)
        },
        responses() {
            const self = this;
            if( !self.value ){
                return [];
            }
            if( self.isString ){
                return [self.$store.getters["questions/parseValueToString"](self.id, self.value)]
            }
            else if( self.isLabelled ){
                return self.value.map( v => {
                    return self.$store.getters["questions/labelledAnswer"](self.id, v)
                })
                
            }
            else{
                return self.value.map( v => {
                    return v;
                    // return self.$store.getters["questions/hierarchicalAnswer"](self.id, v)
                })
            }
        }, 

    }
}
</script>