<template>
	<span>
		<mw-dialog
			v-model="dialog"
			:title="title"
			cancel-btn
			submit-btn
			@submit="save"
		>
			<template v-slot:button>
				<v-btn icon @click="dialog = true">
					<v-icon>mdi-plus-circle</v-icon>
				</v-btn>
			</template>
			<template v-slot:dialog-body>
				Use this to set the link for the participant. You can only set links
				once. If you have made a mistake, please contact Meisterworks.
				<v-text-field
					v-model="linkText"
					label="Link"
					@blur="checkMedium"
					:append-icon="icon"
				></v-text-field>
			</template>
		</mw-dialog>
	</span>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "AddLink",
	props: {
		link: {type: String}, 
		medium: {type: String}, 
		id: {type: String }, 
		name: {type: String }, 
		start: {type: Object }, 
		open: {type: Boolean }
	},
	computed: {
		title() {
			let str = `Adding link for ${this.name}`;
			if (this.time) {
				str += ` (${this.time})`;
			}
			return str;
		},
		time() {
			if (!this.start) {
				return "";
			}
			return (
				this.start.toDate().toLocaleDateString() +
				" " +
				this.start
					.toDate()
					.toLocaleTimeString({}, { minute: "2-digit", hour: "2-digit" })
			);
		},
		icon() {
			let icon;
			let medium = this.medium;
			switch (medium) {
				case "google meet":
					icon = "mdi-google";
					break;
				case "zoom":
					icon = "mdi-alpha-z-circle";
					break;
				case "ms teams":
					icon = "mdi-microsoft-teams";
					break;
				case "skype":
					icon = "mdi-skype";
					break;
				default:
					icon = "mdi-message-video";
			}
			return icon;
		},
	},
	data: () => {
		return {
			linkText: null,
			types: {
				"google meet": "meet.google",
				zoom: "zoom",
				"ms teams": "teams",
				skype: "skype",
			},
			dialog: false,
		};
	},
	components: {
		MwDialog,
	},
	methods: {
		checkMedium() {
			let link = this.linkText;
            let video_medium = "";
			for (var key in this.types) {
				if (link.includes(this.types[key])) {
					video_medium = key;
				}
			}
            this.$store.commit("appointments/CHANGE_MEDIUM", {id: this.id, medium: video_medium});
		},
		save() {
			this.$store.dispatch("appointments/patch", {
				id: this.id,
				participation_link: this.linkText,
				video_medium: this.medium 
			});
		},
	},
	created(){
		if( this.open ){
			this.dialog = true;
		}
	}
};
</script>
