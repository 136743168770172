var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":_vm.mobileBreakpoint},scopedSlots:_vm._u([_vm._l((_vm.headers),function(column){return {key:("item." + (column.value)),fn:function(ref){
var item = ref.item;
return [_c('appointment-cell',{key:((item.id) + "-" + (column.value)),attrs:{"column":column,"item":item}})]}}}),{key:"item.append",fn:function(ref){
var item = ref.item;
return [(item.methodology == 'video')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clickRow(item.appointment)}}},[_c('v-icon',[_vm._v("mdi-video")])],1):_vm._e(),(
					item.appointment_status == 'complete' &&
						item.video_medium == 'internal'
				)?_c('request-recordings',{attrs:{"id":item.appointment_id}}):_vm._e()]}},(_vm.showExport)?{key:"footer.prepend",fn:function(){return [(_vm.filtered)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":_vm.clearFilters}},[_vm._v("Clear Filters")]):_vm._e(),_c('export',{attrs:{"rows":_vm.downloadRows,"columns":_vm.columns,"name":"responses"}}),_vm._t("append-buttons"),_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.rotated),callback:function ($$v) {_vm.rotated=$$v},expression:"rotated"}},[_c('v-btn',{attrs:{"value":false}},[_c('v-icon',[_vm._v("mdi-table")])],1),_c('v-btn',{attrs:{"value":true}},[_c('v-icon',[_vm._v("mdi-text-box")])],1)],1)]},proxy:true}:null,{key:"foot",fn:function(){return [(!_vm.rotated)?_c('tr',_vm._l((_vm.columns),function(column,i){return _c('td',{key:("oot-" + i)},[(column.filterable)?_c('filter-footer',{attrs:{"items":_vm.rows,"column":column},model:{value:(_vm.filter[column.value]),callback:function ($$v) {_vm.$set(_vm.filter, column.value, $$v)},expression:"filter[column.value]"}}):_vm._e()],1)}),0):_vm._e()]},proxy:true}],null,true)}),_c('click-appointment',{on:{"close":function($event){_vm.focus = null}},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }