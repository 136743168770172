<template>
	<tr>
		<td v-if="isScheduled">
			{{ appointmentStart.toDate().toLocaleDateString() }} 
			{{
				appointmentStart
					.toDate()
					.toLocaleTimeString({}, { hour: "2-digit", minute: "2-digit" })
			}}
		</td>
		<td>
            {{ name }}
            <participant-profile class="ml-2" :value="id"></participant-profile>
        </td>
		<td>
			{{ status }}
		</td>
		<td>
			<span>
				<a v-if="link" :href="link">{{ link }}</a>
				<add-link
					v-else
					:link="link"
					:start="appointmentStart"
					:name="name"
					:medium="medium"
					:id="appointmentId"
					:open="trigger"
				></add-link>
			</span>
		</td>
		<td>
            <span v-if="!consented">-</span>
			<consent-view
                v-else
				:id="id"
				:start="appointmentStart"
				:project="project"
                :consented="consented"
			></consent-view>
		</td>
		<td>
			<attendance :id="id" :comments="comments" :status="status"></attendance>
		</td>
	</tr>
</template>

<script>
import AddLink from "@c/project/AddLink";
import ConsentView from "@c/consent/ConsentView";
import ParticipantProfile from "@c/dashboard/ParticipantProfile";
import Attendance from "@c/project/Attendance";

export default {
	name: "SessionRow",
	props: {
		id: { type: String },
		appointmentId: { type: String },
		user: { type: String },
		appointmentStart: { type: Object },
		isScheduled: { type: Boolean },
		status: { type: String },
		medium: { type: String },
		link: { type: String },
		consented: { type: Boolean },
        project: {type: String},
        comments: {type: String},
		trigger: { type: Boolean },
	},
	computed: {
		name() {
			let contact = this.$store.state.users.data[this.user];
			return contact ? contact.first_name || contact.name : "";
		},
	},
	components: {
		AddLink,
        ConsentView,
        ParticipantProfile,
		Attendance
	},
};
</script>
