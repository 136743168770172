<template>

    
    <mw-dialog v-model="dialog" large v-if="haveConsent" cancel-btn :title="title">
        <template v-slot:button>
            <v-btn x-small @click="dialog = true" color="secondary" :disabled="disabled">View Consent</v-btn>
            <v-tooltip left open-on-hover v-if="disabled">
                <template v-slot:activator="{on}">
                    <v-icon small color="secondary" v-on="on" >mdi-help-circle</v-icon>
                </template>
                <span v-if="disabled">Consent information is only viewable for confirmed or complete appointments</span>
            </v-tooltip>
        </template>
        <template v-slot:dialog-body>
            <single-response v-if="consent" :response="consent"></single-response>
            <vue-html2pdf v-if="consent" :ref="`pdf-${id}`" :filename="title" :manual-pagination="false" :paginate-elements-by-height="1400" pdf-format="a4" >
                <section slot="pdf-content" >
                    <div style="padding: 20px">

                        <h3 style="margin-bottom: 10px">{{title}}</h3> 
                        <h4 style="margin-bottom: 10px">Appointment date: {{dateStart}}</h4> 
                        <single-response v-if="consent" :response="consent"></single-response>
                    </div>
                </section>
            </vue-html2pdf>
        </template>
        <template v-slot:dialog-buttons>
                <v-btn color="primary" @click="download">Download PDF</v-btn>
        </template>
    </mw-dialog>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

import MwDialog from "@c/ui/MwDialog";
import SingleResponse from "@c/dashboard/SingleResponse";
export default {
    name: "ConsentView", 
    props: {
        id: { type: String }, 
        consented: {type: Boolean }, 
        start: {type: Object},
        project: {type: String }
    },
    data: () => {
        return {
            dialog: false,
        }
    },
    computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
        title(){
            return `${this.projectName} Consent`
        },
        disabled(){
            return false;
        },
        haveConsent(){
            return this.consented;
        }, 
        consents(){
            return this.$store.getters.getByKey("consents", "appointment_participant", this.id );
        },

        dateStart(){
            if( !this.start ){
                return "";
            }
            return this.start.toDate().toLocaleDateString();
        },
        dateCreated(){
            return this.consent ? this.consent.created_at.toDate().toLocaleDateString() : "";
        },
        projectName(){
            return this.$store.getters["projects/name"](this.project)
        },
        consent(){
            let consents = this.consents;
            if( !this.consents || !this.consents.length ){
                return;
            }
            consents = this.mwutils.sortByDate(consents, "created_at");
            return consents[consents.length - 1];
        }
    },
    components: {
        MwDialog,
        SingleResponse,
        VueHtml2pdf
    }, 
    methods: {
        download(){
            this.$refs[`pdf-${this.id}`].generatePdf()
        }
    },
    watch: {
        haveConsent: {
            immediate: true, 
            handler(v){
                if( v ){
                    let q = {key: "appointment_participant", value: this.id }
                    this.$store.dispatch("consents/fetchByKey", q )
                }
            }
        }
    }


}
</script>