<template>
	<v-sheet>
		<v-data-table
			
			:headers="headers"
			:items="items"
			:mobile-breakpoint="mobileBreakpoint"
		>
			<template
				v-for="column in headers"
				v-slot:[`item.${column.value}`]="{ item }"
			>
				<appointment-cell
					:key="`${item.id}-${column.value}`"
					:column="column"
					:item="item"
				></appointment-cell>
			</template>
			<template v-slot:item.append="{ item }">
				<v-btn
					v-if="item.methodology == 'video'"
					@click="clickRow(item.appointment)"
					icon
				>
					<v-icon>mdi-video</v-icon>
				</v-btn>
				<request-recordings
					v-if="
						item.appointment_status == 'complete' &&
							item.video_medium == 'internal'
					"
					:id="item.appointment_id"
				></request-recordings>
			</template>
			<template v-slot:footer.prepend v-if="showExport">
				<v-btn color="primary" x-small v-if="filtered" @click="clearFilters"
					>Clear Filters</v-btn
				>
				<export
					:rows="downloadRows"
					:columns="columns"
					name="responses"
				></export>
				<slot name="append-buttons"> </slot>
				<v-btn-toggle v-model="rotated" color="primary">
					<v-btn :value="false">
						<v-icon>mdi-table</v-icon>
					</v-btn>
					<v-btn :value="true">
						<v-icon>mdi-text-box</v-icon>
					</v-btn>
				</v-btn-toggle>
			</template>
			<template v-slot:foot>
				<tr v-if="!rotated">
					<td v-for="(column, i) in columns" :key="`oot-${i}`">
						<filter-footer
							v-if="column.filterable"
							:items="rows"
							:column="column"
							v-model="filter[column.value]"
						></filter-footer>
					</td>
				</tr>
			</template>
		</v-data-table>
		<click-appointment
			v-model="focus"
			@close="focus = null"
		></click-appointment>
	</v-sheet>
</template>

<style lang="less">
th.question {
	min-width: 200px;
}
</style>

<script>
import Export from "@c/downloads/Export";
import AppointmentCell from "@c/project/AppointmentCell";
import FilterFooter from "@c/project/FilterFooter";
import ClickAppointment from "@c/project/ClickAppointment";
import RequestRecordings from "@c/project/RequestRecordings";

export default {
	name: "AppointmentTable",

	props: {
		rows: { type: Array, default: () => [] },
		columns: { type: Array },
		showSelect: { type: Boolean, default: true },
		showContacts: { type: Boolean, default: true },
		showDate: { type: Boolean, default: true },
		showExport: { type: Boolean, default: true },
	},
	data: () => {
		return {
			rotated: false,
			mobileBreakpoint: 600,
			filter: [],
			focus: null,
		};
	},
	inject: ["projectId"],
	computed: {
		project() {
			return this.$store.state.projects.data[this.projectId];
		},
		headers() {
			return this.columns.concat({ value: "append", text: "Available Actions" });
		},
		responses() {
			return this.$store.state.surveyResponses.data;
		},
		items() {
			const self = this;
			let rows = self.rows.map((item) => {
				var row = item;
				row.row_id = row.response;
				let response = self.responses[item.response];
				if (response) {
					row = self.makeAnswerColumns(response, row);
				}
				row = self.makeContactColumns(row);
				return row;
			});
			for (var key in self.filter) {
				if (self.filter[key].length) {
					rows = rows.filter((row) => self.filter[key].includes(row[key]));
				}
			}
			return rows;
		},
		downloadRows() {
			const self = this;
			return self.items.map((row) => {
				return {
					...row,
					attended: row.appointment_status == "complete" ? "yes" : "",
					start: row.start
						? self.mwtimeutils
								.parseDateToMoment(row.start)
								.format("HH:mm DD/MM/YYYY")
						: "",
				};
			});
		},
		filtered() {
			return Object.keys(this.filter).length > 0;
		},
	},
	methods: {
		clickRow(id) {
			this.focus = id;
		},
		getAnswer(value, question ){
			question = this.$store.state.questions.data[question];
			if( !question ){
				return;
			} 
            if( !value ){
                return;
            }

			if (["comment", "input"].includes(question.type)) {
                return value
			}
			if (["tiles", "checkboxes"].includes(question.type)) {
				let answer_options = question.answer_options;
				if (question.multiple) {
					return value.map((v) => {
						return answer_options[v]
							? answer_options[v].text
							: v;
					}).join(", ");
				}
				return answer_options[value]
					? answer_options[value].text
					: value;
			}
			return value;
		},
		makeContactColumns(row) {
			let contacts = this.$store.getters.getParticipantContacts(
				row.participant_id
			);
			for (var key in contacts) {
				row[key] = contacts[key];
			}
			return row;
		},
		makeAnswerColumns(response, row) {
			const self = this;
			for (var key in response.data) {
				if (typeof response.data[key] == "object") {
					for (var subq in response.data[key]) {
						if (Array.isArray(response.data[key])) {
							row[key] =  self.getAnswer(response.data[key], key);
						}
						row[`${key}-${subq}`] = response.data[key][subq];
					}
				} else {
					row[key] = self.getAnswer(response.data[key], key);
				}
			}
			return row;
		},
		clearFilters() {
			this.filter = {};
		},
	},
	watch: {
		rotated: {
			handler(v) {
				if (v) {
					this.mobileBreakpoint = 3000;
				} else {
					this.mobileBreakpoint = 600;
				}
			},
		},
	},
	components: {
		Export,
		AppointmentCell,
		FilterFooter,
		RequestRecordings,
		ClickAppointment,
	},
};
</script>
