<template>
	<span>
		<v-btn-toggle :value="value" @change="(v) => update('appointment_status', v)">
			<v-btn x-small value="complete">
				Attended
			</v-btn>
			<v-btn x-small value="no-show">
				No Show
			</v-btn>
		</v-btn-toggle>
		<mw-dialog small v-model="dialog" submit-btn @submit="update('client_comments', commentText )">
			<template v-slot:dialog-body>
				<v-textarea v-model="commentText" label="Your Comments">

				</v-textarea>
			</template>
		</mw-dialog>
	</span>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "Attendance",
	props: {
		id: {type: String},
		status: {type: String},
		comments: {type: String}
	},
	data: () => {
		return {
			dialog: false,
			commentText: null
		}	
	},
	components: {
		MwDialog
	},
	computed: {
		value() {
			let status = "";
			if (["no-show", "complete"].includes(this.status)) {
				status = this.status;
			}
			return status;
		},
	},
	watch: {
		value(v){
			if( v == "no-show" ){
				this.dialog = true;
			}
		},
	},
	methods: {
		update(key, v) {
			if (v) {
				this.$store.dispatch("appointmentParticipants/patch", {
					id: this.id,
					[key]: v,
				});
			}
		},
	},
	created() {
		this.commentText = this.comments;
	}	
};
</script>
