<template>
	<v-card flat>
		<h2 class="font-weight-light">{{ project.client_name }}</h2>

		<p class="mt-3">{{ intro }}</p>
		<project-info :id="id"></project-info>
	</v-card>
</template>

<script>
import ProjectInfo from "@c/project/ProjectInfo";
export default {
	name: "ProjectIntro",
	components: {
		ProjectInfo,
	},
	props: {
		id: { type: String },
		intro: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},
};
</script>
