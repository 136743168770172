<template>
    <mw-dialog v-model="dialog" @submit="submit" submit-btn cancel-btn title="Generate Recording">
        <template v-slot:button>
            <v-btn icon @click="dialog = true"><v-icon>mdi-download-circle</v-icon></v-btn>
        </template>
        <template v-slot:dialog-body>
            This will submit a request for the recording to be processed. These recordings can take 24 hours to complete. 
        </template>
    </mw-dialog>
</template>

<script>

import MwDialog from "@c/ui/MwDialog"
export default {
    name: "RequestRecordings",
    props: {
        id: {type: String}
    },
    components: {
        MwDialog
    }, 
    data: () => {
        return {
            dialog: false
        }
    },
    computed: {
        appointment(){
            return this.$store.state.appointments.data[this.id]
        },
        project(){
            if( !this.appointment ){
                return;
            }
            return this.$store.state.projects.data[this.appointment.project]
        },
        message() {
            let user = this.$store.state.auth.data;
            let message = {
                project: this.project.internal_name, 
                user: user.name, 
                appointment: this.id, 
                start: this.mwtimeutils.formatAsDateTimeString(  this.appointment.start.toDate() )
            }
            return message;
        }, 
    }, 
    methods: {

        submit(){
            let send = {
                to: "research@m.works", 
                template: {
                    name: "recording_request", 
                    data: this.message
                } 
            }
            this.$store.dispatch("emails/insert", send )
        }
    }
}
</script>