<template>
	<vue-excel-xlsx
		:columns="headers"
		:data="rows"
		:filename="name">
		<v-btn v-if="icon" icon :outlined="outlined"><v-icon>mdi-download</v-icon></v-btn>
		<v-btn color="primary" text small outliend v-else>{{text || $t("buttons.download")}}</v-btn>
	</vue-excel-xlsx>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">    
	import VueExcelXlsx from "vue-excel-xlsx";
    import Vue from "vue";
    Vue.use(VueExcelXlsx);

	export default {
		name: "Export", 
		props: {
			rows: {},
			columns: {},
			icon: {type: Boolean, default: false },
			outlined: {type: Boolean, default: false },
			name: {default: "file"}, 
			text: {type: String }
		},
		data: () => {
			return {
				
				}
		},
		computed: {
			headers(){
				var columns = this.columns;
				return columns.map( c => {
					return {field: c.value, label: c.text}
				});
			}
		},

	}
// </script>"