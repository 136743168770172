<template>
	<v-sheet flat v-if="survey">
		<div v-for="question in questions" :key="question.id">
			<p style="margin-bottom: 10px; margin-top: 20px;"><b v-html="getTitle(question.id)"></b></p>
			<p v-if="!isHierarchical(question.id)">
				<question-response
					:id="question.id"
					:value="response.data[question.id]"
				></question-response>
			</p>
			<div v-else>
				<div v-for="subquestion in question.subquestions" :key="subquestion.value">
					<span class="subtitle-2">
						{{ subquestion.text }} 
					</span>
					<question-response
						v-if="response.data[question.id]"
						:id="question.id"
						:value="response.data[question.id][subquestion.value]"
					></question-response>
				</div>
			</div>
		</div>
	</v-sheet>
</template>

<script>
import QuestionResponse from "@c/dashboard/QuestionResponse";

export default {
	name: "SingleResponse",
	props: {
		response: { type: Object },
	},
	data: () => {
		return {};
	},
    components: {
        QuestionResponse
    },
	computed: {
		survey() {
			if (!this.response) {
				return;
			}
			return this.$store.state.surveys.data[this.response.survey];
		},
		questions() {
			const self = this;
			if (!self.response) {
				return [];
			}
			let questions = self.$store.getters["surveys/questionsForResults"](
				self.response.survey
			);

			return questions.map( q => self.$store.state.questions.data[q]).filter( q => q) 
		},
	},
	created() {
		if (this.response.survey) {
			this.$store.dispatch("surveys/fetchById", this.response.survey);
		}
	},
	methods: {
		isHierarchical(id){
			return this.$store.getters["questions/isHierarchical"](id);
		},
		getTitle(id) {
			return this.$store.getters["questions/title"](id);
		},
	},
};
</script>
