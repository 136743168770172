<template>
	<mw-dialog
		large
		v-model="dialog"
		submit-btn
		cancel-btn
		@submit="dialog = false"
		:title="`${name}'s Profile`"
	>
		<template v-slot:button>
			<v-btn x-small color="primary" @click="dialog = true">
				Profile
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<single-response v-if="response" :response="response"></single-response>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import SingleResponse from './SingleResponse.vue';
export default {
	name: "ParticipantProfile",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		appointmentParticipant() {
			return this.$store.state.appointmentParticipants.data[this.value];
		},
		appointment() {
			if (!this.appointmentParticipant) {
				return;
			}
			return this.$store.state.appointments.data[
				this.appointmentParticipant.appointment
			];
		},
		response() {
			if (!this.appointment) {
				return;
			}
			return this.$store.state.surveyResponses.data[
				this.appointmentParticipant.response
			];
		},
		name() {
			let name = "Participant";
			let contacts = this.$store.getters.getParticipantContacts(this.value);
			if (contacts) {
				name = contacts.name;
			}
			return name;
		},
	},
	components: {
		MwDialog,
		SingleResponse
	},
};
</script>
